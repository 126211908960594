var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SmartMedia", {
    attrs: {
      src: _vm.src,
      paused: _vm.paused,
      autoplay: "",
      volume: 1,
      looped: "true",
    },
    on: {
      ended: function ($event) {
        _vm.paused = true
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }