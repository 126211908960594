<template>
  <SmartMedia
    :src="src"
    :paused="paused"
    autoplay
    :volume="1"
    looped="true"
    @ended="paused = true"
  />
</template>

<script>
import User from "@shared/User"
import MissionType from "@shared/enums/Mission"

import SmartMedia from "@/modules/smart-media/components/SmartMedia.vue"

const SCRIBE_SOUND = require("@/assets/scribe.mp3")

export default {
  name: "ScribeSound",
  components: {
    SmartMedia
  },
  data() {
    return {
      paused: true
    }
  },
  computed: {
    isJeopardAi() {
      return (
        this.$store.getters.getCurrentMission?.behavior ===
        MissionType.JeopardAI
      )
    },
    viewer() {
      return this.$store.state.auth.user
    },
    isViewerScribe() {
      if (User.isScribe(this.viewer)) return true
      if (!this.isJeopardAi) return false
      const scribes = this.$store.state.JeopardAi.scribes ?? []
      return scribes.some(user => user.id === this.viewer.id)
    },
    src() {
      return this.$store.state.auth.client?.scribeSound ?? SCRIBE_SOUND
    }
  },
  watch: {
    isViewerScribe(value) {
      if (!value) return
      this.paused = false
    }
  }
}
</script>
